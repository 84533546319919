import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import WhyUs from './components/WhyUs';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Stats from './components/Stats';
import AboutUs from './components/AboutUs';
import Partners from './components/Partners';
import { ThemeProvider, createTheme } from '@mui/material';
import { useRef } from 'react';


function App() {
  const topBanner = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const serviceRef = useRef(null)

  const scrollTo = (section) => {
    if (section === "home" && topBanner && topBanner.current) {
      topBanner.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    if (section === "about" && aboutRef && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    if (section === "contact" && contactRef && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    if (section === "service" && serviceRef && serviceRef.current) {
      serviceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }


  const theme = createTheme({
    palette: {
      primary: { main: "#2C4E63" }
    }
  })


  return <ThemeProvider theme={theme}>
    <Header scrollTo={scrollTo} />
    <div ref={topBanner}>
      <Hero scrollTo={scrollTo} />
    </div>

    <WhyUs scrollTo={scrollTo} />
    <Stats />
    <div ref={aboutRef}>
      <AboutUs />
    </div>
    <div ref={serviceRef}>
      <Services />
    </div>
    <Partners />
    <div ref={contactRef}>
      <ContactUs />
    </div>
    <Footer />
  </ThemeProvider>;
}

export default App;
