
const Stats = () => {
    return <div className="stats flex flex-col gap-10 justify-center items-center md:p-4 p-2">
        <h1 className="text-3xl font-bold">Our Company in Numbers</h1>
        <div className="grid grid-cols-4 w-full gap-8">
        <div className="col-span-2 md:col-span-1 flex flex-col justify-center items-center">
                <div className="text-4xl font-bold">10+</div>
                <div className="text-2xl text-center font-light text-gray-200">Years of experience</div>
            </div>
            <div className="col-span-2 md:col-span-1 flex flex-col justify-center items-center">
                <div className="text-4xl font-bold">50+</div>
                <div className="text-2xl text-center font-light text-gray-200">Professional Collaborations</div>
            </div>
            
            <div className="col-span-2 md:col-span-1 flex flex-col justify-center items-center">
                <div className="text-4xl font-bold">170+</div>
                <div className="text-2xl text-center font-light text-gray-200">Happy Clients</div>
            </div>
            <div className="col-span-2 md:col-span-1 flex flex-col justify-center items-center">
                <div className="text-4xl font-bold">6+</div>
                <div className="text-2xl text-center font-light text-gray-200">Countries Served</div>
            </div>
        </div>
    </div>
}

export default Stats;