import quickbooks from "../images/accounting/quickbook.png";
import xero from "../images/accounting/2 Xero.png";
import myob from "../images/accounting/3 Myob.png";
import zoho from "../images/accounting/4 Zoho.png";
import sage from "../images/accounting/5 Sage 50.png";
import gusto from "../images/accounting/6 Gusto.png";
import netsuite from "../images/accounting/7 Netsuite.png";
import tally from "../images/accounting/8 Tally.jpg";

import drake from "../images/tax/1 Drake.png";
import lacerte from "../images/tax/2 Lacerte.png";
import taxLink from "../images/tax/3 Tax Pro link.png";
import proconnect from "../images/tax/4 intuit proconnect.png";

import hubdoc from "../images/task/1 Hubdoc.jpg";
import canopy from "../images/task/2 Canopy.png";
import karbon from "../images/task/3 Karbon.png";
import fincent from "../images/task/4 Financial Cents.png";
import dext from "../images/task/5 Dext.png";
import bill from "../images/task/6 Bill.com_Logo_2019.png";

const Partners = () => {
    return <div className="px-4 py-8 flex flex-col items-center partners">
        <div className="text-3xl font-bold">Softwares we are working with</div>
        <div className="text-2xl font-bold mt-6">Accounting Softwares</div>

        <div class="grid grid-cols-4 gap-4 mt-4 w-full">
            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <span><img src={quickbooks} alt="sw" className="partner-w" /></span>
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center">
                <img src={xero} alt="sw" className="partner-h" />
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center">
                <img src={myob} alt="sw" className="partner-h" />
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <img src={zoho} alt="sw" style={{ height: "40px" }} />
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center">
                <img src={sage} alt="sw" style={{ height: "60px" }} />
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center">
                <img src={gusto} alt="sw" style={{ height: "60px" }} />
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center">
                <img src={netsuite} alt="sw" style={{ height: "60px" }} />
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center">
                <img src={tally} alt="sw" style={{ height: "60px" }}/>
            </div>
        </div>

        <div className="text-2xl font-bold mt-10">Tax Preparation Softwares</div>
        <div class="grid grid-cols-4 gap-4 w-full">
            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <span><img src={drake} alt="sw" className="partner-w" /></span>
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <img src={lacerte} alt="sw"  style={{ height: "50px" }}/>
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center">
                <img src={taxLink} alt="sw" className="partner-h mt-4"/>
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <img src={proconnect} alt="sw" className="partner-w" />
            </div>
        </div>

        <div className="text-2xl font-bold mt-10">Task Management Softwares</div>
        <div class="grid grid-cols-4 gap-4 w-full">
            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <span><img src={hubdoc} alt="sw" className="partner-w" /></span>
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <img src={canopy} alt="sw" className="partner-w" />
            </div>
            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <img src={karbon} alt="sw" className="partner-w" />
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <img src={fincent} alt="sw" className="partner-w" />
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center items-center hidden md:inline-block"></div>

            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <img src={dext} alt="sw" className="partner-h" />
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center items-center">
                <img src={bill} alt="sw" className="partner-w" />
            </div>

            <div className="col-span-2 md:col-span-1 flex justify-center items-center hidden md:inline-block"></div>

        </div>
    </div>
}

export default Partners;