import { Button } from "@mui/material";

const WhyUs = ({scrollTo}) => {
    // return <div className="p-4 flex flex-col justify-center items-center gap-4 w-full md:w-1/2 text-center">
    //     <h1 className="text-3xl font-bold">About Us</h1>
    // </div>

    return <div className="grid grid-cols-12 gap-4 px-4 py-8 place-items-center">
        <div className="col-span-12 md:col-span-4 items-center">
            <div className="text-3xl md:text-4xl md:px-10 px-2 font-bold text-center">Why you can trust Paramount: <span className="italic">Our Values</span></div>
        </div>
        <div className="col-span-12 md:col-span-8 flex flex-col gap-2">
            <p><span class="font-semibold">Confidentiality: </span>We prioritize the security and confidentiality of your financial information, using advanced technologies to protect your data.</p>
            <p><span class="font-semibold">Flexible Time Zone Support: </span>We understand the importance of timely communication and support. Our team works according to your time zone, ensuring that we are available when you need us the most, no matter where you are located.</p>
            <p><span class="font-semibold">Fixed Price: </span>We charge Fixed Price especailly for CPAs and Accounting Firms and also Fixed contract price irrespective of what amount is finally charged from end client</p>
            <p><span class="font-semibold">Single Point of Contact: </span>Simplify your communication with a dedicated account manager who will be your single point of contact, ensuring consistency and a personalized experience</p>
            <p><span class="font-semibold">Weekly/Periodic Meetings/Anytime Chat: </span>Stay informed and engaged with regular meetings tailored to your schedule. We provide weekly or periodic updates to discuss your financial status and address any concerns</p>
            {/* <p className="mt-4 text-gray-400">At Paramount Consultancy, we pride ourselves on being more than just an accountancy and book keeping firm. Established with a mission to provide top-notch financial services, we have built a reputation for delivering personalized and innovative solutions that cater to the diverse needs of our clients. Our team of highly qualified professionals is dedicated to helping businesses and individuals achieve their financial goals with precision and integrity.</p>
            <p className="mt-4 text-gray-400">At Paramount Consultancy, we believe in the power of proactive financial management. Our team stays ahead of industry trends and regulatory changes to provide you with timely and relevant advice. We are committed to continuous learning and development, ensuring that we are always equipped with the latest tools and knowledge to serve you better. Our proactive approach not only helps in mitigating risks but also in identifying opportunities for our clients to thrive in a competitive marketplace.</p>
            <p className="mt-4 text-gray-400">We are passionate about building long-term relationships based on trust and mutual respect. Our clients' success is our ultimate goal, and we work tirelessly to ensure that we are always delivering value. Whether you are a small business owner, a large corporation, or an individual seeking expert financial advice, Paramount Consultancy is here to support you with unparalleled expertise and a commitment to excellence. Join us on the journey to financial success and experience the Paramount difference.</p> */}
            <div className="mt-4">
                {/* <button type="button" className="mt-4 text-white bg-gradient-to-r from-teal-700 via-teal-800 to-teal-900 hover:bg-gradient-to-br font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                    Book Consultation
                </button> */}
                <Button variant="contained" className="mt-6" onClick={() => { scrollTo("contact") }}>
                    Book Consultation
                </Button>
            </div>
        </div>
    </div>
}

export default WhyUs;