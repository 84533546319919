import email from "../images/email.png";
import phone from "../images/phone.png";
import address from "../images/address.png";
import { Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const ContactUs = () => {
    const { control, handleSubmit } = useForm({ mode: "all" });
    const myLatLng = { lat: 21.1841907, lng: 72.8306824 };
    const onSubmit = (data) => {
        console.log(data);
        axios.post("https://paramount-api-three.vercel.app/", data).then(response => {
            toast.success("Thank you for showing interest. We will contact you soon.")
        }).catch(error => {
            toast.error("Error while submitting the request. Please try again.")
        })
    }

    return <div className="contact flex flex-col items-center px-4 py-8">
        <div className="text-3xl font-bold">Get Started Today!</div>
        <div className="text-gray-500 text-center">
            Ready to take your business to the next level with professional accounting and bookkeeping services? Contact us today for a consultation!
        </div>
        <div className="grid grid-cols-2 w-full gap-4 mt-8">
            <div className="col-span-2 md:col-span-1 md:pl-40 flex flex-col gap-3">
                <div className="flex gap-4 items-center">
                    <img src={email} alt="email" className="contact-icon" />
                    <a href="mailto:admin@paramountconsultancy.in">admin@paramountconsultancy.in</a>
                </div>

                <div className="flex gap-4 items-center">
                    <img src={phone} alt="email" className="contact-icon" />
                    <a href="tel:919824364394">+91 9824364394</a>
                </div>

                <div className="flex gap-4">
                    <img src={address} alt="email" className="contact-icon" />
                    <span>2/244, 2nd Floor, Maleshwar Street,<br />
                        Rustampura, Near Udhna Darwaja,<br />
                        Ring Road, Surat - 395002 (Gujarat, India)</span>
                </div>

                <div className="flex">
                    {/* AIzaSyD6yjqOE3V1s7PIHPh1jHR--MCBQHnyJJo */}
                    <APIProvider apiKey={"AIzaSyD6yjqOE3V1s7PIHPh1jHR--MCBQHnyJJo"}>
                        <Map
                            style={{ width: '100%', height: '300px' }}
                            defaultCenter={myLatLng}
                            defaultZoom={18}
                            gestureHandling={'greedy'}
                            disableDefaultUI={false}
                        >
                            <Marker position={myLatLng} />
                        </Map>
                    </APIProvider>
                </div>
            </div>

            <form className="col-span-2 md:col-span-1 flex flex-col gap-4 md:px-6 py-6 md:py-0" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="name"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "Name is required"
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Your Name" variant="outlined" size="small"
                        helperText={error ? error.message : ""}
                        error={!!error}
                    />)} />

                <Controller name="email"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "Email is required"
                        },
                        pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Invalid email address',
                        },
                    }}
                    render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Your Email" variant="outlined" size="small"
                        helperText={error ? error.message : ""}
                        error={!!error}
                    />)} />

                <Controller
                    name="purpose"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "Purpose of Meeting is required"
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Purpose of Meeting" variant="outlined" size="small"
                        helperText={error ? error.message : ""}
                        error={!!error}
                    />)} />

                <Controller
                    name="message"
                    control={control}
                    render={({ field, fieldState: { error } }) => (<TextField {...field} id="outlined-basic" label="Message" variant="outlined" size="small" multiline rows={3}
                        helperText={error ? error.message : ""}
                        error={!!error}
                    />)} />

                <Button variant="contained" type="submit">
                    Book Consultation
                </Button>
                {/* <button type="button" className="mt-4 text-white bg-gradient-to-r from-teal-700 via-teal-800 to-teal-900 hover:bg-gradient-to-br font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                    Book Consultation
                </button> */}
            </form>
        </div>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    </div >
}

export default ContactUs;