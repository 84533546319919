import logo from "../images/logo.png";

const Header = ({scrollTo}) => {
    return <>
        <nav className="flex items-center justify-between flex-wrap p-4 border-b header">
            <div className="flex items-center flex-no-shrink text-white mr-6">
                <img src={logo} alt="logo" className="logo"/>
            </div>
            
            <div className="w-full md:flex md:items-center md:w-auto">
                <div className=" md:flex-grow flex gap-4">
                    <a href="javascript:void(0)" className="text-cyan-900 block mt-4 md:inline-block md:mt-0 mr-4 font-semibold hover:underline" onClick={() => { scrollTo("home") }}>
                        Home
                    </a>
                    <a href="javascript:void(0)" className="text-cyan-900 block mt-4 md:inline-block md:mt-0 mr-4 font-semibold hover:underline" onClick={() => { scrollTo("about") }}>
                        About Us
                    </a>
                    <a href="javascript:void(0)" className="text-cyan-900 block mt-4 md:inline-block md:mt-0 mr-4 font-semibold hover:underline" onClick={() => { scrollTo("service") }}>
                        Service
                    </a>
                    <a href="javascript:void(0)" className="text-cyan-900 block mt-4 md:inline-block md:mt-0 font-semibold hover:underline" onClick={() => { scrollTo("contact") }}>
                        Contact Us
                    </a>
                </div>
            </div>
        </nav>
    </>
}

export default Header;