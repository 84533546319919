import bookKeeping from "../images/book-keeping.png";
import accounting from "../images/accounting.png";
import taxPlanning from "../images/tax.png";
import finCon from "../images/financial-consultant.png";
import payable from "../images/payable.png";
import payroll from "../images/payroll.png";
import migration from "../images/migration.png";

const Services = () => {
    return <div className="flex flex-col items-center service px-4 py-6 gap-8">
        <h1 className="text-3xl font-bold">Our Services</h1>
        <div className="grid grid-cols-4 gap-8 w-full">
            <div className="card p-8 col-span-4 md:col-span-1 flex flex-col items-center">
                <img src={bookKeeping} alt="book-keeping" className="service-icon" />
                <span className="text-2xl font-semibold mt-2">Book keeping</span>
                <div className="mt-4 text-gray-500">Keep your financial records organized and up-to-date with our comprehensive bookkeeping services</div>
            </div>

            <div className="card p-8 col-span-4 md:col-span-1 flex flex-col items-center">
                <img src={accounting} alt="book-keeping" className="service-icon" />
                <span className="text-2xl font-semibold mt-2">Accounting</span>
                <div className="mt-4 text-gray-500">From financial statements to tax preparation, we provide a full range of accounting services to keep your business compliant and profitable</div>
            </div>

            <div className="card p-8 col-span-4 md:col-span-1 flex flex-col items-center">
                <img src={taxPlanning} alt="book-keeping" className="service-icon" />
                <span className="text-2xl font-semibold mt-2">Tax Preparation</span>
                <div className="mt-4 text-gray-500">Maximize your tax benefits and ensure compliance with our expert tax planning and preparation services</div>
            </div>

            <div className="card p-8 col-span-4 md:col-span-1 flex flex-col items-center">
                <img src={finCon} alt="book-keeping" className="service-icon" />
                <span className="text-2xl font-semibold mt-2">Financial Consulting</span>
                <div className="mt-4 text-gray-500">Gain insights into your financial performance and make informed decisions with our strategic financial consulting services</div>
            </div>

            <div className="card p-8 col-span-4 md:col-span-1 flex flex-col items-center">
                <img src={payable} alt="book-keeping" className="service-icon" />
                <span className="text-2xl font-semibold mt-2">AP/AR Management</span>
                <div className="mt-4 text-gray-500">Streamline your cash flow with our expert management of your accounts payable and receivable, ensuring timely payments and efficient invoicing</div>
            </div>

            <div className="card p-8 col-span-4 md:col-span-1 flex flex-col items-center">
                <img src={payroll} alt="book-keeping" className="service-icon" />
                <span className="text-2xl font-semibold mt-2">Payroll Processing</span>
                <div className="mt-4 text-gray-500">Simplify your payroll process with our efficient and reliable payroll solutions</div>
            </div>

            <div className="card p-8 col-span-4 md:col-span-1 flex flex-col items-center">
                <img src={migration} alt="book-keeping" className="service-icon" />
                <span className="text-2xl font-semibold mt-2">Data Migration</span>
                <div className="mt-4 text-gray-500"> Transition your financial data seamlessly with our secure and efficient data migration services, minimizing disruptions to your business operations</div>
            </div>
        </div>
    </div>
}

export default Services;