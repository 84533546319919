import { Button } from "@mui/material";
import hero from "../images/hero.png";

const Hero = ({scrollTo}) => {
    return <div className="hero h-screen">
        <div className="w-full flex flex-col md:flex-row">
            <div className="flex flex-col justify-center items-center p-4 gap-2 sm: pt-40">
                <h1 className="text-3xl text-center font-bold">Welcome to Paramount Consultancy</h1>
                <span className="text-lg text-slate-500 text-center">Your Trusted Partner in Accounting & Bookkeeping</span>

                <Button variant="contained" className="mt-6" onClick={() => { scrollTo("contact") }}>
                    Book Consultation
                </Button>
            </div>
            <div className="h-screen hero-img-container flex items-end">

                <img src={hero} alt="hero" />
            </div>
        </div>
    </div>
}

export default Hero;