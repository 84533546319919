
const AboutUs = () => {
    return <div className="flex flex-col items-center px-4 py-8 gap-8 about">
        <div className="text-3xl font-bold">About Us</div>

        <div class="flex flex-col gap-4 md:px-20 text-center">
            <p>Paramount consultancy was established in Year 2014 to serve as Accounting / Bookkeeping and Tax Preparation Services to local clients. In Year 2016, Paramount consultancy had started overseas outsourcing services with a view to providing Accounting/ Bookkeeping and Tax Preparation services to overseas clients primarily associated with CPAs and Accounting Firm.</p>
            <p>Paramount consultancy has more than 10 years of experience and serving more than 170 clients in various countries.</p>
        </div>
    </div>
}

export default AboutUs;